import { RouteObject } from 'react-router-dom';

import { lazyLoadHelper } from '@utils/lazyLoadHelper';

export const routes: RouteObject[] = [
  {
    index: true,
    lazy: lazyLoadHelper(() => import('@vault/pages/HomePage/HomePage')),
    path: '',
  },
  {
    lazy: lazyLoadHelper(
      () => import('@vault/pages/MeshVisualizerPage/MeshVisualizerPage'),
    ),
    path: ':collectionId/*artifactIds/mesh',
  },
];

export default routes;